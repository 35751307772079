import styles from './page-footer.module.css';
import jollyroomLogo from './../../public/jollyroom-logo.svg';
import ugleungerLogo from './../../public/ugleunger-logo.png';
import bellinoLogo from './../../public/bellino-logo.png';
import moonboonLogo from './../../public/moonboon-logo.svg';
import milkerLogo from './../../public/milker-logo.svg';
import babysjarmLogo from './../../public/babysjarm-logo.png';
import kaosLogo from './../../public/kaos-logo.png';
export default function PageFooter() {
	return (
		<footer className={styles.footer}>
          	Babymoon.no
			<div style={{fontSize: '14px', marginTop: '8px'}}>Produkter fra kjente norske butikker:</div>
			<div className={styles.logoContainer}>
				<a href="https://go.jollyroom.no/t/t?a=1329191907&as=1907976616&t=2&tk=1" target="_blank" rel="sponsored nofollow"><img src={jollyroomLogo.src} className={styles.logo} alt="Jollyroom logo" style={{height: '42px'}} /></a>
				<a href="https://ugleunger.no" target="_blank" rel="sponsored nofollow"><img src={ugleungerLogo.src} className={styles.logo} alt="Ugleunger logo" style={{height: '42px'}} /></a>
				<a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=104103" target="_blank" rel="sponsored nofollow"><img src={bellinoLogo.src} className={styles.logo} alt="Bellino logo" style={{height: '42px'}} /></a>
				<a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=92924" target="_blank" rel="sponsored nofollow"><img src={moonboonLogo.src} className={styles.logo} alt="Moonboon logo" style={{height: '42px'}} /></a>
				<a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=71734" target="_blank" rel="sponsored nofollow"><img src={milkerLogo.src} className={styles.logo} alt="Milker logo" style={{height: '42px'}} /></a>
				<a href="https://clk.tradedoubler.com/click?p=343212&a=3365458" target="_blank" rel="sponsored nofollow"><img src={babysjarmLogo.src} className={styles.logo} alt="Babysjarm logo" style={{height: '42px'}} /></a>
				<a href="https://to.www.itskaos.com/t/t?a=1958195923&as=1907976616&t=2&tk=1" target="_blank" rel="sponsored nofollow"><img src={kaosLogo.src} className={styles.logo} alt="Kaos logo" style={{height: '42px'}} /></a>
			</div>
			<a href="/om-oss" style={{fontSize: '12px'}}>Om Babymoon.no</a><br/>
			<span style={{fontSize: '12px'}}>E-post: post@babymoon.no</span><br/>
			<span style={{fontSize: '12px'}}>&copy; Copyright {new Date().getFullYear()}</span>
		</footer>
	);
}